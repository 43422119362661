import { BiCopy } from "react-icons/bi";
import { Box, TextField, Typography } from "@mui/material";
import { FormattedDate, FormattedTime, useIntl } from "react-intl";
import { IApiResponse, IMeta } from "./restModel";
import { getMeta, getPassword } from "./restFacade";
import { parseJSON } from "date-fns";
import { useAuth } from "oidc-react";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CopyToClipboard from "react-copy-to-clipboard";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useAPIError from "./Dialogs/error/useAPIError";

export default function PasswordGenerate() {
  const auth = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const { addError } = useAPIError();

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const [meta, setMeta] = useState<IMeta>();

  const [error, setError] = useState<IApiResponse>();

  const [notFound, setNotFound] = useState(false);
  const [forbidden, setForbidden] = useState(false);

  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (auth && auth.userData) {
      console.debug(`Loading meta for auth="${auth}", auth.userData="${auth.userData}"`);
      loadMeta();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  function loadMeta() {
    getMeta(auth)
      .then((data) => {
        if (data !== null) {
          setMeta(data);
          setError(undefined);
        } else {
          enqueueSnackbar(f({ id: "errorloadingpasswordmeta.message" }), { variant: "warning" });
        }
      })
      .catch((err) => handleRequestError(err, f({ id: "errorloadingpasswordmeta.message" })));
  }

  function generatePassword() {
    getPassword(auth)
      .then((data) => {
        if (data !== null) {
          setMeta(data.meta);
          setPassword(data.password);
        } else {
          enqueueSnackbar(f({ id: "errorfetchingnewpassword.message" }), { variant: "warning" });
        }
      })
      .catch((err) => handleRequestError(err, f({ id: "errorfetchingnewpassword.message" })));
  }

  function handleRequestError(err: any, errorMessage: string) {
    if (err.response.status === 403) {
      setForbidden(true);
    } else if (err.response.status === 404) {
      setNotFound(true);
    } else {
      if (err.response.data !== null) {
        setError(err.response.data);
      } else {
        addError(errorMessage, "error");
      }
    }
  }

  const ValidText = () => {
    if (error != null && error != undefined) {
      if (error.status === 2) {
        return <Typography align="center">{f({ id: "connectionerror.message" })}</Typography>;
      }
      if (error.status === 3) {
        return <Typography align="center">{f({ id: "missingengargs.message" })}</Typography>;
      }
      if (error.status === 4) {
        return <Typography align="center">{f({ id: "sqlerror.message" })}</Typography>;
      }
      if (error.status === 5) {
        return <Typography align="center">{f({ id: "dbusernotfound.message" }) + error.message}</Typography>;
      }
      if (meta != null) {
        return <Typography align="center">{f({ id: "errorfetchingnewpassword.message" })}</Typography>;
      }
      return <Typography align="center">{f({ id: "errorloadingpasswordmeta.message" })}</Typography>;
    }
    if (meta != null && !meta.valid) {
      return <Typography align="center">{f({ id: "notvalid.message" })}</Typography>;
    } else if (meta != null && meta.valid) {
      const validUntil = parseJSON(meta.expiration);

      return (
        <Typography align="center">
          {f({ id: "validuntil.message" })}
          <br />
          <strong>
            <FormattedDate value={validUntil} year="numeric" month="long" day="numeric" weekday="long" />{" "}
            <FormattedTime value={validUntil} />
          </strong>
        </Typography>
      );
    } else {
      return null;
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const PasswordField = () => {
    if (!password) return null;

    return (
      <div>
        <FormControl variant="standard" style={{ width: "100%" }}>
          <TextField
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={f({ id: "togglepasswordvisibility.message" })}>
                    <IconButton size="small" onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </Tooltip>
                  <CopyToClipboard text={password}>
                    <Tooltip title={f({ id: "copytoclipboard.message" })}>
                      <IconButton size="small">
                        <BiCopy></BiCopy>
                      </IconButton>
                    </Tooltip>
                  </CopyToClipboard>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
    );
  };

  if (notFound) {
    return (
      <div>
        <Typography align="center">{f({ id: "generatepassword.usernotfound" })}</Typography>
      </div>
    );
  }

  if (forbidden) {
    return (
      <div>
        <Typography align="center">{f({ id: "forbidden.message" })}</Typography>
      </div>
    );
  }

  if (!meta && (error === null || error === undefined)) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress sx={{ my: 1 }} />
        <Typography> {f({ id: "generatepassword.loadingmetadata" })}</Typography>
      </Box>
    );
  }

  if (!auth || !auth.userData) {
    return <div></div>;
  }

  if (error != null && error != undefined) {
    return <ValidText />;
  }

  return (
    <>
      <ValidText />

      <Box my={2}>
        <PasswordField />
      </Box>
      <Box>
        <Button variant="contained" onClick={() => generatePassword()} fullWidth>
          {f({ id: "generatepassword.message" })}
        </Button>
      </Box>
    </>
  );
}
